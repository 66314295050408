import React from "react";
import { Link } from "react-router-dom";

function Logo({ logoSource, bootstrapNav }) {
  if (bootstrapNav) {
    return (
      <Link 
        className="navbar-brand d-flex align-items-center site-logo" 
        to="/"
      >
        <img 
          className="img-fluid logo-img" 
          src={logoSource} 
          alt="Krys Newman" 
        />
      </Link>
    );
  }

  return (
    <div className="site-logo align-items-center">
      <Link className="d-flex align-items-center" to="/">
        <img 
          className="img-fluid logo-img" 
          src={logoSource} 
          alt="Krys Newman" 
        />
      </Link>
    </div>
  );  
}


export default Logo;
