import React, { useEffect, useState } from "react";
import Header from "../components/layouts/Header";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PaperDetails(props) {
  const urlTitle = props.match.params.urlTitle;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // Set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const useScreenSize = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return isMobile;
  };

  const isMobile = useScreenSize();


  return (
    <>
      <Header
        logoSource="/images/logo-header.png"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container" style={{ maxWidth: "700px" }}>
            <div className="blog-single shadow-dark p-30 center">
              <Document
                file={`../../../papers/${urlTitle}.pdf`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                { isMobile ? 
                  <Page pageNumber={pageNumber} width={Math.min(windowSize.width * 0.85, 400)}/> : 
                  <Page pageNumber={pageNumber}/> }
              </Document>
            </div>
          </div>
        </div>

        <div className="spacer" data-height="96"></div>
        <div className="page-controls">
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <i className="fas fa-angle-left"></i>
          </button>
          {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <i className="fas fa-angle-right"></i>
          </button>
        </div>
      </main>
    </>
  );
}

export default PaperDetails;
