export const allBlogs = [
  {
    id: 1,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/linear-regression.md",
    date: "09 February, 2020",
    author: "Bolby",
    category: "Reviews",
  },
  {
    id: 2,
    title: "Common Misconceptions About Payment",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Bolby",
    category: "Tutorial",
  },
  {
    id: 3,
    title: "3 Things To Know About Startup Business",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Bolby",
    category: "Business",
  },
];

export const allPapers = [
  {
    id: 1,
    title: "Linear Regression with TensorFlow on TimeSeries Data",
    image: "images/blog/ml.webp",
    filesource: "papers/linear-regression.pdf",
    date: "22 September, 2018",
    author: "Krys",
    urlTitle: "linear-regression",
    category: "Machine Learning",
  },
  {
    id: 2,
    title: "Machine Vision for Safer Self Driving Cars in Construction Zones",
    image: "images/blog/machine-vision.jpeg",
    filesource: "../../papers/machine-vision-construction.pdf",
    date: "06 December, 2018",
    author: "Krys",
    urlTitle: "machine-vision-construction",
    category: "Machine Vision",
  },
  {
    id: 4,
    title: "Time Series Forecasting with Neural Nets",
    image: "images/blog/nn.jpeg",
    filesource: "../../papers/time-series-nn.pdf",
    date: "14 October, 2018",
    author: "Krys",
    urlTitle: "time-series-nn",
    category: "Machine Learning",
  },
  {
    id: 3,
    title: "Machine Vision for Safer Self-Driving Cars",
    image: "images/blog/machine-vision.jpeg",
    filesource: "../../papers/machine-vision-self-driving-cars.pdf",
    date: "03 September, 2018",
    author: "Krys",
    urlTitle: "machine-vision-self-driving-cars",
    category: "Machine Vision",
  },
  
];

export const filters = [
  {
    id: 1,
    text: "Everything",
  },
  {
    id: 2,
    text: "Career",
  },
  {
    id: 3,
    text: "Personal",
  },
  {
    id: 4,
    text: "Hobbies",
  },
];

export const allData = [
  {
    id: 1,
    title: "Jiu Jitsu Purple Belt",
    category: "hobbies",
    image: "images/bjj.png",
    popupLink: ["images/bjj.png"],
  },
  {
    id: 2,
    title: "Brivo Access Control",
    category: "career",
    image: "images/brivo.png",
    link: ["https://www.brivo.com/products/access-control/"],
  },
  {
    id: 3,
    title: "Corporate Insurance Website Build",
    category: "personal",
    image: "images/ci.png",
    link: ["https://www.corpinsurance.net/"],
  },
  {
    id: 4,
    title: "M1 BJJ Website Build",
    category: "personal",
    image: "images/m1bjj.png",
    link: ["https://www.m1bjj.com/"],
  },
  {
    id: 5,
    title: "Data Science Capstone",
    image: "images/capstone.png",
    link: ["https://github.com/knewman23/data-science-capstone/blob/master/Krys%20Newman%20Capstone.pdf"],
    filesource: "../../papers/capstone.pdf",
    date: "22 September, 2022",
    author: "Krys",
    urlTitle: "data-science-capstone",
    category: "career",
  },
];
